export const initOtpForm = () => {
  const form = document.getElementById("otp-form");
  if (!form) return;
  const inputs = <HTMLInputElement[]>[
    ...form.querySelectorAll("input[type=text]"),
  ];
  if (inputs.length === 0) return;
  inputs[0].focus();
  const submit = <HTMLFormElement>form.querySelector("button[type=submit]");

  const handleKeyDown = (e: Event) => {
    const keyboardE = <KeyboardEvent>e;
    if (
      !/^[0-9]{1}$/.test(keyboardE.key) &&
      keyboardE.key !== "Backspace" &&
      keyboardE.key !== "Delete" &&
      keyboardE.key !== "Tab" &&
      !keyboardE.metaKey &&
      !(keyboardE.ctrlKey && keyboardE.key === "v") // Allow Ctrl+V
    ) {
      keyboardE.preventDefault();
    }

    if (keyboardE.key === "Delete" || keyboardE.key === "Backspace") {
      const index = inputs.indexOf(<HTMLInputElement>e.target);
      if (index == inputs.length - 1) {
        if (inputs[index].value == "") {
          inputs[index - 1].focus();
        }
        inputs[index].value = "";
        return;
      }
      if (index > 0) {
        inputs[index - 1].value = "";
        inputs[index - 1].focus();
      }
    }
  };

  const handleInput = (e: Event) => {
    const { target } = e;
    if (!target) return;
    const index = inputs.indexOf(<HTMLInputElement>target);
    if ((<HTMLInputElement>target).value) {
      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
      } else {
        submit.focus();
      }
    }
  };

  const handleFocus = (e: Event) => {
    if (!e.target) return;
    (<HTMLInputElement>e.target).select();
  };

  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault();

    if (!e.clipboardData) return;
    const text = e.clipboardData.getData("text");

    // Assuming `inputs` is an array of HTMLInputElement
    if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    inputs.forEach((input, index) => {
      if (index < digits.length) {
        input.value = digits[index];
      }
    });
    submit.focus();
  };

  inputs.forEach((input) => {
    input.addEventListener("input", handleInput);
    input.addEventListener("keydown", handleKeyDown);
    input.addEventListener("focus", handleFocus);
    input.addEventListener("paste", handlePaste);
  });
};
