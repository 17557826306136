import { FilterResponse } from "../types/filterType";

export const getProjects = async (
  viewType?: string
): Promise<FilterResponse> => {
  let url = "/api/users/tasks/projects";
  if (viewType) {
    url += `?view_type=${viewType}`;
  }

  const resp = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "GET",
  });

  const respJson = await resp.json();
  return <FilterResponse>respJson;
};
