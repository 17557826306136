// @ts-ignore
import Datepicker from "flowbite-datepicker/Datepicker";
import { initToast } from "./components/toasts";
import htmx from "htmx.org";

export const checkEmpty = (value: any): any | null => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === "string" && value.trim() === "") {
    return null;
  }

  if (typeof value === "number" && isNaN(value)) {
    return null;
  }

  if (value instanceof Date && isNaN(value.getTime())) {
    return null;
  }

  return value;
};

export const handleHttpError = (resp: Response, respJson: any) => {
  if (resp.ok) {
    return;
  }
  initToast(respJson.message);
  throw new Error(respJson.message || `HTTP error! Status: ${resp.status}`);
};

export const decodeHtmlEntities = (encodedString: string): HTMLElement => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = encodedString;
  return <HTMLElement>tempDiv.firstChild;
};

export const processHTMX = () => {
  htmx.process(document.body);
};

export const newDatePicker = (datepickerEl: HTMLElement | null): Datepicker => {
  return new Datepicker(datepickerEl, {
    // format: "yyyy-mm-dd",
    autohide: true,
    buttonClass: "",
    todayBtn: true,
    clearBtn: true,
    weekStart: 1,
    todayBtnMode: 1,
    //gtodayHighlight: true,
    disableTouchKeyboard: true,
    keyboardNavigation: false,
    view: "days",
    minView: "days",
    maxView: "years",
    // startDate: new Date(),
    // endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    autoclose: true,
  });
};
