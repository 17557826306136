import { Drawer, DrawerOptions, InstanceOptions } from "flowbite";

export function initToday() {
  const $targetEl = document.getElementById("today-image");
  const $body = document.getElementById("today-sidebar-google-calendar-portal");

  const options: DrawerOptions = {
    placement: "left",
    onHide: () => {
      $body?.classList.add("justify-center");
      $body?.classList.remove("justify-end");

      $targetEl?.classList.remove("-translate-x-full")
    },
    onShow: () => {
      $body?.classList.remove("justify-center");
      $body?.classList.add("justify-end");
    },
  };

  const instanceOptions: InstanceOptions = {
    id: "today-image",
    override: true,
  };

  const drawer = new Drawer($targetEl, options, instanceOptions);
  const button = document.getElementById("today-image-button");

  button?.addEventListener("click", () => {
    drawer.toggle();
  });
}
