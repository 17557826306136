import { Dismiss } from "flowbite";
import type { DismissOptions } from "flowbite";
import type { InstanceOptions } from "flowbite";

// options object
const options: DismissOptions = {
  transition: "transition-opacity",
  duration: 1000,
  timing: "ease-out",

  // callback functions
  //@ts-ignore
  onHide: (context, targetEl) => {},
};

// instance options object
const instanceOptions: InstanceOptions = {
  id: "targetElement",
  override: true,
};

export const initToast = (message: string): Dismiss => {
  const toastHTML = document.createElement("div");
  toastHTML.innerHTML = JSON.parse(
    <string>(
      (<HTMLFormElement>document.querySelector("#toast-html")).textContent
    ) || ""
  );

  (<Element>toastHTML.querySelector("#toast-body")).innerHTML = message;

  document.getElementById("toast")?.remove();

  document.body.appendChild(toastHTML);

  const toast = new Dismiss(
    document.getElementById("toast"),
    document.getElementById("dismiss-toast"),
    options,
    instanceOptions
  );
  setTimeout(() => {
    toast.hide();
  }, 5000);
  //toast.hide();
  return toast;
};
