import Alpine from "alpinejs";
import { formatDate } from "date-fns";
import { initOtpForm } from "./auth";
import {
  deleteAction,
  initActionPriorityDropdown,
  initEditActionName,
  updateAction,
} from "./components/actions";
import { dispatchTaskDoneEvent } from "./components/calendar";
import {
  initFilterDropdown,
  initializeCalendar,
  initTipTapEventDescription,
  initTipTapEventName,
} from "./components/fullcalendar";
import { openNewTaskModal } from "./components/modals";
import {
  afterSettingsLoad,
  hideColorFilterDropdown,
  initEditFilterName,
  initFilterColorDropdown,
} from "./components/settings";
import {
  addNewTask,
  addToTasks,
  afterDeleteTask,
  calendarAccordion,
  exportTaskToMD,
  initTipTapEditTaskName,
  initTipTapNewTaskName,
  initTipTapTaskDescription,
  updateTask,
  updateTipTapNewTaskName,
} from "./components/tasks";

export const appStore = () => {
  Alpine.store("viewType", "work");
  Alpine.store("currentDate", formatDate(new Date(), "yyyy-MM-dd"));
  Alpine.store("label", "");
  Alpine.store("project", "");
  Alpine.store("googleCalendar", "");

  const priorities = (<HTMLFormElement>document.querySelector("#priorities"))
    ?.textContent;
  if (priorities) {
    Alpine.store("priorities", JSON.parse(priorities));
  }
  Alpine.store("tasks", {
    addNewTask,
    initTipTapNewTaskName,
    calendarAccordion,
    updateTipTapNewTaskName,
    dispatchTaskDoneEvent,
    initTipTapEditTaskName,
    afterDeleteTask,
    exportTaskToMD,
    initTipTapTaskDescription(
      content: string = "",
      update: boolean = true,
      selector: string
    ) {
      initTipTapTaskDescription(content, update, selector);
    },
    updateTask(taskField: string, taskVal: any, taskID: string) {
      updateTask(taskField, taskVal, taskID);
    },
    updateAction,
    deleteAction(btn: HTMLInputElement, actionID: string) {
      deleteAction(btn, actionID);
    },
    initEditActionName,
    initActionPriorityDropdown(el: HTMLElement) {
      initActionPriorityDropdown(el);
    },
    initOtpForm() {
      initOtpForm();
    },
    openNewTaskModal,
  });

  Alpine.store("calendar", {
    addToTasks,
    initTipTapEventName,
    initTipTapEventDescription,
    initFilterDropdown,
    initializeCalendar
  });

  Alpine.store("settings", {
    afterSettingsLoad,
    initEditFilterName,
    initFilterColorDropdown,
    hideColorFilterDropdown,
  });
};

export const getAlpineDataByID = (id: string): any => {
  return Alpine.$data(<HTMLElement>document.getElementById(id)) as any;
};
