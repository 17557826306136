import hotkeys from "hotkeys-js";
import { addNewTaskModal, openNewTaskModal } from "./components/modals";

export const initHotkeys = () => {
  // @ts-ignore
  hotkeys("t", (event: any, handler: any) => {
    if (addNewTaskModal.isVisible()) {
      return;
    }
    openNewTaskModal();
  });
};
