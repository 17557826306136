// main.ts
import "tom-select/dist/css/tom-select.css";
import "./fonts";
import "./style.css";

import "vite/modulepreload-polyfill";

import "./hotkeys";

import { initFlowbite } from "flowbite";
import htmx from "htmx.org";
//import { loadHTMXMultiSwap } from "./htmx/multiswap";

import {
  calcNumberOfTasks,
  displatchViewTypeEvent,
  initSortable,
  initWeeklyView,
} from "./components/calendar";

import {
  onTaskEditModal,
  openEditTaskModal,
  TaskColumnPrefix,
  updateTiptapPanelDate,
} from "./components/tasks";

import Alpine from "alpinejs";
import {
  initFlowbiteModals,
  setupModalEventListeners,
} from "./components/modals";
import "./components/toasts";
import { initHotkeys } from "./hotkeys";
import { appStore } from "./store";

import { Editor } from "@tiptap/core";
import "js-circle-progress";
import { RightSidebar } from "./components/right-sidebar";
import {
  tiptapLabelPersonalInput,
  tiptapLabelWorkInput,
} from "./components/settings";
import { initToday } from "./components/today";

window.Alpine = Alpine;
// window.htmx = htmx;
//loadHTMXMultiSwap();

document.addEventListener("alpine:init", () => {
  appStore();
});

// Init Flowbite
initFlowbite();
initFlowbiteModals();

// Setup event listeners.
window.addEventListener("load", () => {
  initHotkeys();
  openEditTaskModal();
  updateTiptapPanelDate();
});

// htmx.onLoad(function (element: Element) {
//   //console.log(element);
// });

interface AppInterface {
  InitWeeklyView: (toDate: string, direction: string) => void;
  OnTaskEditModal: any;
  DisplatchViewTypeEvent: any;
  CalcNumberOfTasks: any;
  TiptapLabelWorkInput: Editor;
  TiptapLabelPersonalInput: Editor;
  TiptapProjectWorkInput: Editor;
  TiptapProjectPersonalInput: Editor;
}

htmx.on("htmx:before-request", (evt: Event) => {
  console.log(evt);
});

htmx.on("htmx:after-request", (evt: Event) => {
  const customEvent = <CustomEvent>evt;
  const targetId = customEvent.detail.requestConfig.headers["HX-Trigger"];

  // If inital weekly view load
  if (targetId === "weekly-task-view") {
    initWeeklyView(<string>Alpine.store("currentDate"), "");
  }

  // If horizonatal scroll reaches certain trigger point
  if (targetId?.startsWith(TaskColumnPrefix)) {
    const taskColumnDate = targetId.substring(TaskColumnPrefix.length);

    initWeeklyView(
      taskColumnDate,
      customEvent.detail.requestConfig.parameters?.direction
    );
  }
  if (targetId === "daily-task-view") {
    initSortable();
    setupModalEventListeners();
  }

  // If new task.
  // if (targetId === "task-new") {
  //   if (customEvent.detail.successful) {
  //     document.getElementById("close-modal")?.click();
  //     (<HTMLFormElement>document.getElementById("task-new")).reset();
  //     initFlowbite();
  //   }
  // }

  // If edit task.
  // if (targetId === "task-edit") {
  //   if (customEvent.detail.successful) {
  //     document.getElementById("close-edit-task-modal")?.click();
  //     initFlowbite();
  //   }
  // }

  // if open edit task
  // if (
  //   customEvent.detail.pathInfo.requestPath == "/api/users/tasks/weekly/edit"
  // ) {
  //   tipTapEditor = new TipTapEditor(".tiptap-editor");
  // }
});

new RightSidebar({
  navId: "main-sidebar",
  contentId: "main-sidebar-content",
});

new RightSidebar({
  navId: "today-sidebar",
  contentId: "today-sidebar-content",
  defaultTabId: "today-sidebar-google-tab",
});

initToday();

// Alpine.store("tabs", {
//   current: "first",

//   items: ["first", "second", "third"],
// });

// Set global variable

export const App: AppInterface = {
  InitWeeklyView: initWeeklyView,
  OnTaskEditModal: onTaskEditModal,
  DisplatchViewTypeEvent: displatchViewTypeEvent,
  CalcNumberOfTasks: calcNumberOfTasks,
  TiptapLabelWorkInput: tiptapLabelWorkInput,
  TiptapLabelPersonalInput: tiptapLabelPersonalInput,
  TiptapProjectWorkInput: tiptapLabelWorkInput,
  TiptapProjectPersonalInput: tiptapLabelPersonalInput,
};

window.App = App;

Alpine.start();