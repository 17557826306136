import { Modal, ModalOptions } from "flowbite";
import TomSelect from "tom-select";
import { getFlagSVGbyColor } from "../icons/flag";
import { getHashtagSVGbyColor } from "../icons/hashtag";
import { getLabelSVGbyColor } from "../icons/label";
import { getLabels } from "./labels";
import { getProjects } from "./projects";
import {
  getTaskStartDate,
  initEditTaskAdvancedSelect,
  initEditTaskSelect,
  tiptapNewTaskDescription,
  tiptapNewTaskName,
} from "./tasks";
// @ts-ignore
import Datepicker from "flowbite-datepicker/Datepicker";
import { getAlpineDataByID } from "../store";
import { clearActionsSession, initActions } from "./actions";
import { newDatePicker } from "../utils";

// Modals
export let addNewTaskModal: Modal;
export let editTaskModal: Modal;
export let todayModal: Modal;
export let settingsModal: Modal;

// TomSelect
export let tomLabel: TomSelect;
export let tomProject: TomSelect;
export let tomPriority: TomSelect | null;
export let panelDatePicker: any;

let addNewTaskModalFlag = false;

// export const ModalsInitialized = () => {
//   window.addEventListener("load", function () {
//     InitFlowbiteInstancesAndEvents();
//   });
// };

export const modalOptions: ModalOptions = {
  placement: "center",
  backdrop: "dynamic",
  backdropClasses: "bg-gray-900/50 dark:bg-gray-700/80 fixed inset-0 z-40",
  closable: true,
};

export const initFlowbiteModals = () => {
  if (!tomPriority) {
    tomPriority = initEditTaskSelect("#priority-select-new", getFlagSVGbyColor);
    if (tomPriority) tomPriority.setValue("normal");
  }

  todayModal = new Modal(
    document.getElementById("today-modal"),
    {},
    {
      id: "todayModal",
      override: true,
    }
  );

  addNewTaskModal = new Modal(
    document.getElementById("new-task-modal"),
    modalOptions,
    {
      id: "newTaskModal",
      override: true,
    }
  );
  editTaskModal = new Modal(
    document.getElementById("edit-task-modal"),
    modalOptions,
    {
      id: "editTaskModal",
      override: true,
    }
  );

  settingsModal = new Modal(
    document.getElementById("settings-modal"),
    modalOptions,
    {
      id: "settingsModal",
      override: true,
    }
  );

  // addNewTaskModal = FlowbiteInstances.getInstance("Modal", "new-task-modal");
  // editTaskModal = FlowbiteInstances.getInstance("Modal", "edit-task-modal");
  addNewTaskModal.updateOnHide(() => {
    (<HTMLElement>document.getElementById("task-container-new")).innerHTML = "";
    // reset Due Date picker

    // const dueDateInput = document.getElementById(
    //   "due-date"
    // ) as HTMLInputElement | null;

    // if (dueDateInput) {
    //   dueDateInput.value = "";
    // }

    //tomPriority.destroy();
    //tomPriority.setValue("normal");

    document.querySelectorAll(".tom-input").forEach((el) => {
      el.classList.add("hidden");
    });
    addNewTaskModalFlag = false;
  });
  addNewTaskModal.updateOnShow(async () => {
    addNewTaskModalFlag = true;
    // reset Alpine data for new task modal
    const newTaskModalData = getAlpineDataByID("new-task-modal");
    if (newTaskModalData.task.date) {
      newTaskModalData.task.date = "";
    }

    tiptapNewTaskName.commands.clearContent();
    tiptapNewTaskDescription.commands.clearContent();

    const eventContainer = document.getElementById("event-container-new");
    if (eventContainer) eventContainer.innerHTML = "";

    if (tomLabel) tomLabel.destroy();
    if (tomProject) tomProject.destroy();
    if (tomPriority) tomPriority.setValue("normal");
    tiptapNewTaskName.commands.focus();
    if (!panelDatePicker) {
      const datepickerEl = document.getElementById("panel-date-new");
      panelDatePicker = newDatePicker(datepickerEl);
    }

    // promise all
    const [_, startDate] = await Promise.all([
      clearActionsSession(),
      getTaskStartDate(),
    ]);
    initActions("task-container-new", "");
    panelDatePicker.setDate(new Date(startDate));

    const [labels, projects] = await Promise.all([
      getLabels(Alpine.store("viewType")),
      getProjects(Alpine.store("viewType")),
    ]);
    tomLabel = initEditTaskAdvancedSelect(
      "#label-select-new",
      labels.data,
      "open-label-select-new",
      getLabelSVGbyColor
    );
    tomProject = initEditTaskAdvancedSelect(
      "#project-select-new",
      projects.data,
      "open-project-select-new",
      getHashtagSVGbyColor
    );

    setTimeout(() => {
      document.querySelectorAll(".ts-wrapper").forEach((el) => {
        el.classList.remove("hidden");
      });
    }, 500);
  });

  editTaskModal.updateOnHide(() => {
    document.querySelector(".edit-task-skeleon")?.classList.remove("hidden");
    // clear edit modal body
    document.querySelectorAll("#edit-task-modal .modal-body").forEach((el) => {
      el.innerHTML = "";
    });

    window.history.pushState("", "", "/");
    getAlpineDataByID("edit-task-modal").fullscreen = false;
  });

  editTaskModal.updateOnShow(() => {
    //
  });

  settingsModal.updateOnHide(() => {
    const settingModal = document.querySelector("#settings-modal");
    if (settingModal) settingModal.innerHTML = "";
  });
};

export const setupModalEventListeners = async () => {
  console.log("Setting up modal event listeners");

  // Add new task modal
  document.querySelectorAll(".open-new-task-modal").forEach((el) => {
    el.addEventListener("click", () => {
      openNewTaskModal();
    });
  });
  document.querySelectorAll(".close-new-task-modal").forEach((el) => {
    el.addEventListener("click", () => {
      addNewTaskModal.hide();
    });
  });

  document.querySelectorAll(".close-edit-task-modal").forEach((el) => {
    el.addEventListener("click", () => {
      editTaskModal.hide();
    });
  });

  document.querySelectorAll(".open-today-modal").forEach((el) => {
    el.addEventListener("click", () => {
      todayModal.show();
    });
  });

  document.querySelectorAll(".close-today-modal").forEach((el) => {
    el.addEventListener("click", () => {
      todayModal.hide();
    });
  });

  document.querySelectorAll(".settings-menu-item").forEach((el) => {
    el.addEventListener("click", () => {
      settingsModal.show();
    });
  });

  document.querySelectorAll(".close-settings-modal").forEach((el) => {
    el.addEventListener("click", () => {
      settingsModal.hide();
    });
  });
};

export const openNewTaskModal = () => {
  if (!addNewTaskModalFlag) addNewTaskModal.show();
};
